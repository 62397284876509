<template lang="pug">
  v-container
    v-row
      v-col(cols="12")
        v-toolbar(
          color="yellow lighten-1"
          dense
          outlined
          hide-details="auto"
        )
          span Client: {{ $route.params.customer }}
          v-spacer
          v-icon mdi-account
          v-icon mdi-pencil
        v-alert(
          class="mt-2"
          type="error"
          dismissible
          v-for="(item, index) in custPostErrors"
          :key="index"
        )
          span(class="text-uppercase") {{ item.first() }}
      v-col(
        lg="4"
        cols="12"
      )
        v-autocomplete(
          dense
          outlined
          hide-details="auto"
          label="Customer Category"
          v-model="customerCategoryId"
          :items="custCatList"
          :loading="custCatGetting"
          item-value="id"
        )
          template(v-slot:selection="{ item }")
            span(class="primary--text") {{ item.id }}
            span(class="blue-grey--text mx-1 text-caption") {{ item.name }}
          template(v-slot:item="{ item }")
            span(class="primary--text") {{ item.id }}
            span(class="blue-grey--text mx-1 text-caption") {{ item.name }}
      v-col(
        lg="4"
        cols="12"
      )
        v-text-field(
          dense
          outlined
          hide-details="auto"
          label="Company"
          :loading="custGetting"
          v-model="company"
        )
      v-col(
        lg="4"
        cols="12"
      )
        v-text-field(
          dense
          outlined
          hide-details="auto"
          label="TIN"
          :loading="custGetting"
          v-model="tinNumber"
        )
      v-col(
        lg="4"
        cols="12"
      )
        v-text-field(
          dense
          outlined
          hide-details="auto"
          label="Region"
          :loading="custGetting"
          v-model="region"
        )
      v-col(
        lg="4"
        cols="12"
      )
        v-text-field(
          dense
          outlined
          hide-details="auto"
          label="Province"
          :loading="custGetting"
          v-model="province"
        )
      v-col(
        lg="4"
        cols="12"
      )
        v-text-field(
          dense
          outlined
          hide-details="auto"
          label="City"
          :loading="custGetting"
          v-model="city"
        )
      v-col(
        lg="4"
        cols="12"
      )
        v-text-field(
          dense
          outlined
          hide-details="auto"
          label="Brgy"
          :loading="custGetting"
          v-model="brgy"
        )
      v-col(
        lg="4"
        cols="12"
      )
        v-text-field(
          dense
          outlined
          hide-details="auto"
          label="Purok"
          :loading="custGetting"
          v-model="purok"
        )
      v-col(
        lg="4"
        cols="12"
      )
        v-text-field(
          dense
          outlined
          hide-details="auto"
          label="Street"
          :loading="custGetting"
          v-model="street"
        )
      v-col(
        lg="4"
        cols="12"
        offset-lg="4"
      )
        v-text-field(
          dense
          outlined
          hide-details="auto"
          label="House No"
          :loading="custGetting"
          v-model="houseNo"
        )
      v-col(
        lg="4"
        cols="12"
      )
        v-text-field(
          dense
          outlined
          hide-details="auto"
          label="Contact No"
          v-model="contactNo"
        )
      v-col(
        lg="1"
        cols="12"
      )
        v-btn(
          color="primary"
          dark
          @click="updateCustomer"
          :loading="custPosting"
        )
          span Save
      v-col(
        lg="1"
        cols="12"
      )
        v-btn(
          color="red"
          dark
          @click="$router.push('/customers')"
        )
          span Cancel
</template>
<script>
import custCatRepository from '@/repositories/customer-category.repository'
import customerRepository from '@/repositories/customer.repository'
import { getVars, postVars, errorHandler } from '@/libs/api-helper.extra'
import searchDelay from '@/libs/searchDelay.extra'

const getCustDelay = searchDelay()

const inputVars = () => ({
  customerCategoryId: null,
  tinNumber: null,
  company: null,
  region: null,
  province: null,
  city: null,
  brgy: null,
  purok: null,
  street: null,
  houseNo: null,
  contactNo: null,
})

const camelToSnakeCase = str => str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`)

export default {
  name: 'CreateCustomer',
  created () {
    this.getCustCat()
    this.getCustomer()
  },
  data () {
    return {
      ...inputVars(),
      ...getVars('cust-cat'),
      ...postVars('cust'),
      ...getVars('cust'),
    }
  },
  methods: {
    getCustCat () {
      if (this.custCatGetting) return
      this.custCatGetting = true
      custCatRepository.index()
        .then(({ data }) => { this.custCatList = data })
        .catch(e => { this.custCatGetErrors = errorHandler(e) })
        .then(() => { this.custCatGetting = false })
    },
    getCustomer () {
      if (this.custGetting) return
      this.custGetting = true
      getCustDelay(() => {
        const customerId = this.$route.params.customer
        customerRepository.edit(customerId)
          .then(this.populateInputs)
          .catch(e => { this.custGetErrors = errorHandler(e) })
          .then(() => { this.custGetting = false })
      })
    },
    populateInputs ({ data }) {
      Object.keys(inputVars()).forEach(e => {
        this[e] = data[camelToSnakeCase(e)]
      })
    },
    updateCustomer () {
      if (this.custPosting) return
      this.custPosting = true
      this.custPostErrors = []
      const data = this.getInputData()
      const customerId = this.$route.params.customer
      customerRepository.update(customerId, data)
        .then(() => { this.$router.push('/customers') })
        .catch(e => { this.custPostErrors = errorHandler(e) })
        .then(() => { this.custPosting = false })
    },
    getInputData () {
      return {
        id: this.customerId,
        customer_category_id: this.customerCategoryId,
        company: this.company,
        region: this.region,
        province: this.province,
        city: this.city,
        brgy: this.brgy,
        tin_number: this.tinNumber,
        purok: this.purok,
        street: this.street,
        house_no: this.houseNo,
        contact_no: this.contactNo,
      }
    },
  },
}
</script>
